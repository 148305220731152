.UnserviceableStep {
  position: absolute;
  width: 100%;
  max-width: 500px;
  transform: translateX(130%);
  transition: all 0.5s;
  opacity: 1;
}

.UnserviceableStep.is-inactive {
  transform: translateX(-130%);
  opacity: 0;
}

.UnserviceableStep.is-active {
  transform: translateX(0);
  opacity: 1;
}
