.WidgetGroup {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include mq('sm') {
    flex-direction: row;
  }

  .WidgetGroup__logo {
    position: relative;
    width: 209px;
    height: 18px;
    margin-top: 40px;

    @include mq('sm') {
      position: absolute;
      bottom: 108px;
      width: 223px;
      height: 40px;
    }
  }

  .WidgetGroup__content-inner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .WidgetGroup__content {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 290px;
    min-width: 290px;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 20px 15px 20px 15px;
    overflow: hidden;
    border-radius: 8px;
    background: var(--color-white);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);

    @include mq('sm') {
      display: flex;
      top: 0;
      left: -12%;
      width: 100%;
      min-width: 570px;
      max-width: 636px;
      height: 729px;
      margin-top: auto;
      padding: 30px 44px 60px 44px;
    }
  }
}

.WidgetGroup__unserviceable-flow {
  .WidgetGroup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 290px;
    padding: 40px 15px 20px 15px;

    @include mq('sm') {
      top: 0;
      left: -12%;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-width: 570px;
      max-width: 636px;
      padding: 30px 50px 60px 50px;
    }
  }

  .slide__question.slide__question--unserviceable {
    margin-top: 5px;
    color: var(--color-tooltip-gray);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;

    @include mq('sm') {
      justify-content: center;
      margin-top: 15px;
      padding: 0;
      font-size: 16px;
    }
  }

  .slide__email-group {
    justify-content: space-between;
  }

  .Input {
    width: 100%;
    max-width: 100%;
  }

  .slide__question--community-solar {
    text-align: center;
  }

  .slide__community-solar--cta {
    justify-content: center;
  }
}
