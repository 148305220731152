.tooltip-button-wrapper {
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  padding: 0;
  border: transparent;
  background: transparent;

  @include mq('sm') {
    align-items: flex-end;
    margin-left: 5px;
  }
}

.slide__question--home-info,
.tooltip-button-wrapper {
  @include mq('sm') {
    align-items: center;
  }
}

.tooltip-icon {
  display: inline-block;
  height: 16px;
  margin-top: -2px;

  @include mq('sm') {
    margin-top: 0;
    margin-bottom: -2px;
  }
}

.tippy-tooltip.tippy {
  top: -4px;
  max-width: 213px !important;
  min-height: 94px;
  padding: 15px;
  border-bottom: 5px solid var(--color-secondary);
  border-radius: 3px 3px 0 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--color-white);
  color: var(--color-tooltip-gray);
  font-family: var(--font-primary);
  font-size: 10px;
  line-height: 16px;
  text-align: left;
  filter: drop-shadow(0 0 6px rgba(37, 54, 64, 0.1));

  @include mq('sm') {
    max-width: 234px !important;
    min-height: 120px;
    font-size: 12px;
    line-height: 18px;
  }

  .tippy-arrow {
    &::after {
      content: "";
      display: inline-block;
      transform-origin: 50% 0;
    }
  }

  &[x-placement^='top'] {
    .tippy-arrow {
      position: absolute;
      bottom: -20px;
      border-top: 8px solid var(--color-secondary);
      border-bottom: 8px solid transparent;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        z-index: -9;
        right: 0;
        bottom: -1px;
        transform: rotate(0deg);
        transform-origin: 50% 0;
      }
    }
  }
}

.tippy-tooltip.tippy--home-info {
  right: 45px;

  &[x-placement^='top'] {
    .tippy-arrow {
      left: 169px !important;

      @include mq('sm') {
        left: 151px !important;
      }
    }
  }
}

.tippy-tooltip.tippy--bill-amount {
  top: 1px !important;
  right: 59px;
  height: 94px;

  @include mq('sm') {
    left: -77px !important;
  }

  &[x-placement^='top'] {
    .tippy-arrow {
      left: 155px !important;

      @include mq('sm') {
        left: 183px !important;
      }
    }
  }
}

.tippy-paragraph {
  margin-bottom: 10px;
}
