.slide.is-inactive {
  height: 0;
  transform: translateX(-130%);
  opacity: 0;
}

.slide.is-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  transform: translateX(0);
  opacity: 1;
}

.slide {
  position: relative;
  height: 0;
  transform: translateX(130%);
  transition: opacity 0.5s, height 0.5s, transform 0.5s;
  opacity: 0;
  text-align: center;

  @include mq('sm') {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .slide__step-number {
    color: var(--color-quaternary);
    font-family: var(--font-primary);
    font-size: 12px;
    text-transform: uppercase;
  }

  .slide__heading {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 22px;
    line-height: 38px;

    @include mq('sm') {
      margin-bottom: 6px;
      font-size: 32px;
    }
  }

  .slide__description {
    margin-bottom: 15px;
    color: var(--color-quaternary);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;

    @include mq('sm') {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .slide__question {
    display: flex;
    margin-top: 20px;
    color: var(--color-quaternary);
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;

    @include mq('sm') {
      width: 100%;
      margin-top: 24px;
      line-height: 22px;
    }
  }

  .slide__question--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq('sm') {
      align-items: center;
    }
  }

  .slide__question--home-info {
    display: inline-flex;
    align-items: flex-end;
    width: 100%;

    @include mq('sm') {
      align-items: center;
    }
  }

  .slide__inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq('sm') {
      flex-direction: row;
    }
  }

  .slide__email-group {
    @include mq('sm') {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    .Input {
      margin-top: 20px;

      @include mq('sm') {
        margin-top: 0;
      }

      .Input__input {
        width: 260px;
        height: 46px;
        margin-top: 0;
        padding: 10px 15px;
        border: 1px solid var(--color-accent);
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;

        @include mq('sm') {
          width: 404px;
          height: 58px;
          padding: 20px;
          font-size: 18px;
        }
      }
    }

    .button {
      padding: 15px 20px;

      @include mq('sm') {
        max-width: 113px;
        height: 58px;
        margin-top: 0;
        padding: 20px 25px;
      }
    }

    .button__submit--unserviceable {
      margin-top: 10px;
      margin-bottom: 40px;

      @include mq('sm') {
        margin-top: 0;
      }
    }
  }

  .slide__buttons {
    display: flex;
    align-items: center;

    @include mq('sm') {
      flex-direction: row;
      width: 551px;
    }

    .button {
      margin-top: 5px;
    }
  }

  .slide__buttons--home-info {
    flex-direction: column;
    align-items: unset;

    @include mq('sm') {
      flex-direction: row;
    }

    .button--home-info {
      width: 260px;
      height: 46px;
      margin-top: 5px;
      margin-top: 10px;
      padding: 15px;

      @include mq('sm') {
        width: 126px;
        margin-right: 15px;
      }
    }

    .button--home-info:last-of-type {
      margin-right: 0;
    }
  }

  .button--rent-own {
    width: 122px;
    margin-right: 15px;
    padding: 15px 20px;

    @include mq('sm') {
      width: 268px;
    }
  }

  .button--rent-own:last-of-type {
    margin-right: 0;
  }
}

.slide__score {
  display: inline;
  font-size: 12px;
  font-weight: 400;

  @include mq('sm') {
    display: block;
  }
}

.slide__unserviceable-screen {
  max-width: 660px;

  .slide__description {
    color: var(--color-tooltip-gray);
    font-weight: 300;
    line-height: 22px;

    @include mq('sm') {
      margin-top: 12px;
    }
  }
}

.slide-unserviceable {
  height: 0;

  @include mq('sm') {
    margin-top: 0;
  }

  .slide__heading.unserviceable-heading {
    margin-bottom: 10px;
    padding: 0 8px;
    line-height: 28px;

    @include mq('sm') {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 38px;
    }
  }
}

.slide-unserviceable.is-active {
  @include mq('sm') {
    margin-top: 0;
  }



  .slide__description {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;

    @include mq('sm') {
      font-size: 16px;
    }
  }
}

.slide-unserviceable--success.is-active {
  margin-top: 40px;
  margin-bottom: 40px;

  @include mq('sm') {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.slide-serviceable--success {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq('sm') {
    margin-top: 170px;
  }

  .slide__animated-svg {
    max-width: 74px;
    max-height: 76px;

    @include mq('sm') {
      max-width: 103px;
      max-height: 105px;
    }
  }

  .slide__heading {
    margin-top: 16px;

    @include mq('sm') {
      margin-top: 18px;
    }
  }

  .slide__description {
    max-width: 477px;
    margin-top: 8px;
    padding: 0 8px;
    font-size: 14px;
    line-height: 22px;

    @include mq('sm') {
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.slide-serviceable--success.is-active {
  margin-top: 20px;

  @include mq('sm') {
    margin-top: 140px;
  }
}

.checkbox-wrapper {
  display: flex;
  margin-top: 20px;

  @include mq('sm') {
    margin-top: 30px;
  }

  .checkbox__input {
    position: absolute;
    appearance: none;
  }

  .checkbox__label {
    position: absolute;
    z-index: 999;
    width: 22px;
    height: 22px;
  }

  .checkbox__input:checked + .checkbox__label::before {
    border: 1px solid var(--color-secondary);
    background: var(--color-secondary) url('../../images/icon-check.svg') center no-repeat;
    background-size: 75%;
  }

  .checkbox__label::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 22px;
    height: 22px;
    border: 1px solid var(--color-quaternary);
    border-radius: 2px;
    background-color: #fff;
  }

  .checkbox__input[type="checkbox"]:focus + .checkbox__label::before {
    outline: 5px auto -webkit-focus-ring-color;
  }

  .checkbox__input--text {
    padding-left: 30px;
    color: var(--color-tooltip-gray);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

    @include mq('sm') {
      position: relative;
      margin-bottom: -18px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .tcpa-link-text {
    color: var(--color-secondary);
    text-decoration: none;
  }
}

// Community Solar CTA slide styling
.slide__community-solar-screen.is-active {
  max-width: 660px;
  margin-top: 20px;

  @include mq('sm') {
    margin-top: 177px;
    line-height: 38px;
  }

  .slide__description {
    @include mq('sm') {
      max-width: 660px;
      margin-top: 12px;
    }
  }
}

.is-active .community-solar-heading {
  margin-bottom: 10px;
  padding: 0 8px;
  line-height: 27px;

  @include mq('sm') {
    margin-bottom: 0;
    line-height: 38px;
  }
}

.slide__question.slide__question--community-solar {
  justify-content: center;
  margin-top: 5px;
  padding: 0 8px;
  color: var(--color-tooltip-gray);
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  @include mq('sm') {
    margin-top: 15px;
    font-size: 16px;
    line-height: 22px;
  }
}

.slide__community-solar--cta {
  display: flex;
  justify-content: center;
  width: 100%;
}

.slide__community-solar-redirect.is-active {
  margin-top: 60px;
  margin-bottom: 40px;

  @include mq('sm') {
    margin-top: 238px;
  }
}
