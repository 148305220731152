.Input {
  margin-top: 15px;

  @include mq('sm') {
    width: 268px;
    margin-right: 15px;
  }

  &:first-of-type {
    margin-top: 5px;

    @include mq('sm') {
      margin-top: 15px;
    }
  }

  &:last-of-type {
    @include mq('sm') {
      margin-right: 0;
    }
  }

  .Input__label {
    display: flex;
    position: relative;
    color: var(--color-quaternary);
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    text-align: left;

    @include mq('sm') {
      align-items: flex-start;
      line-height: 22px;
      white-space: nowrap;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"],
  input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input:focus {
    outline: 5px auto var(--color-secondary);
  }

  .Input__input {
    display: flex;
    width: 260px;
    height: 46px;
    margin-top: 5px;
    padding: 15px;
    border: 1px solid var(--color-accent);
    border-radius: 3px;
    background: var(--color-white);
    color: var(--color-quaternary);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;

    @include mq('sm') {
      width: 268px;
      margin-right: 15px;
      font-size: 16px;
      line-height: 16px;
    }
  }

  .Input__input--prefix {
    color: var(--color-accent);
  }

  .Input__input--error {
    border: 2px solid var(--color-error);
    color: var(--color-error);
  }

  .Input__input--error-message {
    display: flex;
    position: absolute;
    margin-top: 5px;
    color: var(--color-error);
    font-size: 12px;
    font-weight: 400;
  }

  .Icon-error {
    margin-right: 5px;
  }
}

.AutoComplete__wrapper {
  position: relative;

  .AutoComplete {
    position: absolute;
    z-index: 99;
    top: 36px;
    max-height: 175px;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow-y: scroll;
    border: 0.5px solid var(--color-nonary);
    border-radius: 3px;
    background: var(--color-white);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    color: var(--color-tooltip-gray);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @include mq('sm') {
      top: 49px;
      max-height: fit-content;
      overflow: hidden;
    }

    .AutoComplete__suggestion {
      display: flex;
      align-items: center;
      height: 50px;
      padding-right: 15px;
      padding-left: 15px;
      cursor: pointer;

      @include mq('sm') {
        height: 58px;
      }

      &:hover {
        background-color: var(--color-senary);
        color: var(--color-tooltip-gray);
      }
    }
  }
}
