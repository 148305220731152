.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  border: 2px solid var(--color-secondary);
  border-radius: 3px;
  background-color: transparent;
  color: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;


  &.button__submit {
    width: 260px;
    height: 46px;
    margin-top: 40px;
    padding: 15px 20px;
    border: 2px solid var(--color-secondary);
    border-radius: 3px;
    background: var(--color-secondary);
    color: var(--color-white);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;

    @include mq('sm') {
      align-self: center;
      width: 151px;
      height: 58px;
      margin-top: 30px;
      font-size: 18px;
      line-height: 18px;
    }

    &:disabled {
      border: none;
      background-color: var(--color-disabled);
      color: var(--color-white);
      cursor: not-allowed;
    }
  }

  &.button__submit--customer-info {
    @include mq('sm') {
      position: relative;
      bottom: -17px;
      width: 113px;
      height: 58px;
      padding: 20px 25px;
    }
  }

  &.is-active {
    background: var(--color-secondary);
    color: var(--color-white);
    font-weight: 700;
  }

  &.button--error {
    border: 2px solid var(--color-error);
  }
}

.slide__community-solar--cta {
  .button__link--community-solar {
    text-decoration: none;
  }

  .button__community-solar {
    width: 179px;
    height: 46px;
    margin-top: 20px;
    padding: 15px;
    border: 2px solid var(--color-secondary);
    border-radius: 3px;
    background: var(--color-secondary);
    color: var(--color-white);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;

    @include mq('sm') {
      align-self: center;
      width: 206px;
      height: 58px;
      margin-top: 30px;
      padding: 20px;
      font-size: 18px;
      line-height: 18px;
    }
  }
}
