.ProgressTracker {
  display: flex;
  top: -52px;
  align-items: center;
  width: 260px;
  height: 26px;
  margin-bottom: 40px;
  padding: 5px 10px;
  border-radius: 50px;
  background: var(--color-octonary);

  @include mq('sm') {
    width: 535px;
    height: 38px;
    margin-bottom: 60px;
    padding: 10px 20px;
  }
}

.ProgressTracker__step-number {
  display: flex;
  width: 100%;
  margin-right: 37px;
  color: var(--color-quaternary);
  font-family: var(--font-primary);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;

  @include mq('sm') {
    margin-right: 62px;
    font-size: 12px;
    line-height: 18px;
  }
}

.ProgressTracker__progress-bar-container {
  display: flex;
  width: 100%;
}

.ProgressTracker__bar {
  width: 43px;
  height: 6px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: var(--color-disabled-secondary);

  @include mq('sm') {
    width: 113px;
    margin-right: 15px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.is-active {
    background-color: var(--color-secondary);
  }
}

.noProgressTracker {
  display: none;
}
