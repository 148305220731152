@import 'reset';

:root {
  // Color variables
  --color-primary: #003f73;
  --color-secondary: #0071cd;
  --color-tertiary: #3c3c3c;
  --color-quaternary: #666;
  --color-quinary: #3c3c3c;
  --color-senary: #eef3f5;
  --color-septenary: #c4c4c4;
  --color-octonary: #f7f9fa;
  --color-nonary: #d6e1e7;
  --color-disabled: #dadada;
  --color-error: #9c4515;
  --color-white: #fff;
  --color-black: #000;
  --color-disabled-secondary: #e3effa;
  --color-tooltip-gray: #253640;
  --color-accent: #bdcfd9;
  --color-dropdown-selected: #dee7ec;
  --color-link-text: #00b9d9;
  // Fonts variables
  --font-primary: 'Inter', sans-serif;
  --font-secondary: 'Merriweather', serif;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
