.Dropdown {
  .Dropdown__control {
    align-content: center;
    height: 46px;
    margin-top: 5px;
    padding: 10px 15px;
    border: 1px solid var(--color-accent);
    border-radius: 3px;
    color: var(--color-quaternary);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    @include mq('sm') {
      padding: 20px;
      font-size: 18px;
      line-height: 18px;
    }

    &:hover {
      border: 1px solid var(--color-accent);
    }
  }

  .Dropdown__option {
    padding: 15px 0 15px 15px;
    color: var(--color-tooltip-gray);

    &--is-focused {
      background-color: var(--color-senary);
    }

    &:hover {
      background-color: var(--color-senary);
    }
  }

  .Dropdown__option--is-selected {
    background-color: var(--color-dropdown-selected);
    color: var(--color-tooltip-gray);
  }

  &.Dropdown__input--error {
    .Dropdown__control {
      border: 2px solid var(--color-error);
    }
  }

  .Dropdown__indicator-separator {
    display: none;
  }

  .Dropdown__indicator {
    svg {
      color: var(--color-secondary);
    }
  }

  .Dropdown__placeholder {
    color: var(--color-quaternary);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;

    @include mq('sm') {
      font-size: 18px;
      line-height: 18px;
    }
  }

  .Dropdown__value-container {
    padding: 0;
    text-align: left;
  }

  .Dropdown__menu {
    height: 175px;
    overflow: scroll;
    text-align: left;

    @include mq('sm') {
      height: fit-content;
      overflow: hidden;
    }
  }

  .Dropdown__menu-list {
    text-align: left;
  }

  .Dropdown__single-value {
    color: var(--color-quaternary);
    font-weight: 400;
  }
}
